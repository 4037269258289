<template>
    <div id="formEntry" ref = 'formEntry'>
        <el-row class="form-wrap">
            <el-col :span="20" style="background: #f7f7f7;" class="col-wrap-s">
                <el-card>
                    
                <p class="form-title">{{headerFormName}}</p>

                <!-- 单表单 -->
                <div class="formCon" v-if="handleItem&&handleItem.selectionType==='Single'">
                    <!--@refresh="getTreeNode(isIndex)"-->
                    <!-- <FormEntryFormSingle :formData="dynamicForm" :dialogStatus="dialogStatus" :onload="isOnload"
                                         formType="Single"
                                         :isShowUP = 'isShowUP'
                                         ref="singleForm" :formStatus="handleItem.status"
                                         @refresh="getTreeNode(handleItem)"
                                         :sectionList="sectionList" :parentExampleId="parentExampleId"
                                         :exampleId="handleItem.exampleId"
                                         :isIndex="isIndex" :researchCode="researchCode" :editKey="editKey"
                                         :handleItem="handleItem" :changePatientId="changePatientId">
                    </FormEntryFormSingle> -->
                     <FormEntryFormSingle :formData="dynamicForm" :dialogStatus="dialogStatus" :onload="isOnload"
                                             formType="Single"
                                             :isFormPreview = 'isFormPreview'
                                             ref="singleForm" :formStatus="handleItem.status"
                                             @refresh="getTreeNode(handleItem)"
                                             @backtop = 'backtop'
                                             @handleNodeClick='handleNodeClick'
                                             @updateDynamicForm='updateDynamicForm'
                                             :sectionList="sectionList" :parentExampleId="parentExampleId"
                                             :exampleId="handleItem.exampleId"
                                             :isIndex="isIndex" :researchCode="researchCode" :editKey="editKey"
                                             :handleItem="handleItem" :changePatientId="changePatientId">
                        </FormEntryFormSingle>
                </div>
                <!-- 多表单 -->
                <div v-if="handleItem&&handleItem.selectionType==='Multiple'" class="formCon">
                    <el-table :data="mFormData" border style="width: 100%" :header-cell-class-name="tableHeaderCn">
                        <template>
                            <el-table-column v-for="col in cols" :key="col.ddCode" :prop="col.ddCode"
                                             :label="col.ddName" align="center" :show-overflow-tooltip="true">
                                <template slot-scope="scope">{{ scope.row[col.ddCode] }}</template>
                            </el-table-column>
                        </template>
                        <el-table-column fixed="right" label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="formDialog('edit',scope.row)">
                                    <i class="iconfont icon-bianji" style="color:#2c5c89" title="编辑"></i>
                                </el-button>
                                <el-button type="text" size="small" @click="formDialog('check',scope.row)">
                                    <i class="iconfont icon-list" style="color:#2c5c89" title="查看"></i>
                                </el-button>
                                <el-button type="text" size="small">
                                    <el-popconfirm title="您确定要删除该数据吗？" @confirm="removeForm(scope.row.id)">
                                        <i slot="reference" style="color:#2c5c89" class="iconfont icon-shanchu" title="删除"></i>
                                    </el-popconfirm>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="text-align:right;margin-top:10px" v-if="cols&&cols.length>0">
                        <el-button :disabled="handleItem.status!==0" type="primary" size="small" @click="formDialog('add')">
                            新增
                        </el-button>
                    </div>
                </div>

                <div v-if="formDialogVisible">
                    <!-- 添加多表 -->
                    <el-dialog :title="textMap[dialogStatus]"
                               :visible.sync="formDialogVisible"
                               custom-class="center-dialog">
                        <FormEntryForm :formData="dynamicForm" ref="multiForm"
                                       :ddValueIds="ddValueIds"
                                       :exampleId="exampleId"
                                       :dialogStatus="dialogStatus" formType="Multiple"
                                       :sectionList="sectionList" :parentExampleId="parentExampleId"
                                       :isIndex="isIndex" :researchCode="researchCode" :editKey="editKey"
                                       @closeFrom="closeFrom" :handleItem="handleItem"
                                       :changePatientId="changePatientId"
                        ></FormEntryForm>
                    </el-dialog>
                </div>
            </el-card>
            </el-col>
            <!-- 右边留白
            <el-col :span="4"></el-col> -->
        </el-row>
    </div>
</template>

<script>
    import {
        getSectionList,
        getSectionTree,
        getTesteeList,
        getVisitList,
        getFormList,
        getTreeNodeData,
        addFormEntry,
        getStructureDdList,
        deleteFormEntry,
        markSection
    } from "@/api/formEntry";
    import {regionDataPlus, CodeToText, TextToCode} from "element-china-area-data";
    import {handleFormData} from "@/utils/index";
    import {Loading} from 'element-ui';
    import FormEntryForm from "../components/FormEntryForm";
    import FormEntryFormSingle from "../components/FormEntryFormSingle";

    let loadingInstance = null;
    export default {
        name: "FormEntry",
        components: {FormEntryForm, FormEntryFormSingle},
        filters: {
            treeNodeNameEllipsis(name) {
                if (name && name.length > 8)
                    return name.slice(0, 14) + '...';
                return name;
            }
        },
        computed: {
            multiFormParam() {
                return {
                    registryEntityCode: this.researchCode,
                    selectionType: this.handleItem.selectionType,
                    code: this.handleItem.code,
                    patientId: this.changePatientId,
                    exampleId: this.handleItem.exampleId,
                    parentCode: this.handleItem.parentCode
                };
            }
        },
        data() {
            return {
                isFormPreview: true,
                isShowUP:false,
                handleItem: {},
                treeData: [],
                isOnload: false,
                // 左侧下拉及表单表头
                projectName: this.$route.query.researchName,
                centerName: this.$route.query.centerName,
                testeeNumber: this.$route.query.testeeName,
                visitName: '',
                headerFormName: '',
                testeeValue: '',
                visitValue: '',
                formValue: '',
                visitItemData: null,
                testeeList: [],
                visitList: [],
                currentMultiOperate: '',
                currentMultiRowId: '',
                regionOptions: regionDataPlus,
                dialogStatus: "",
                textMap: {
                    update: "编辑表单",
                    create: "新增表单",
                    check: "查看表单"
                },
                formDialogVisible: false,
                loading: false,
                researchCode: this.$route.query.registryEntityCode,
                researchName: this.$route.query.researchName,
                parentCode: this.$route.query.parentCode,
                patientId: this.$route.query.patientId,
                exampleId: this.$route.query.exampleId, // 暂无
                ddValueIds: '',
                changePatientId: this.$route.query.patientId,
                outpatientNumber: this.$route.query.outpatientNumber,
                defaultCheckTreeNode: [],
                isIndex: 0,
                sectionList: [],
                dynamicForm: {
                    singleSectionConditionMap: {},
                    sectionDDMap: {},
                    domains: []
                },
                // 多表
                cols: [],
                mFormData: [],
                dataMap: {},
                editKey: "",
                parentExampleId: "",
                // 上传文件
                currentSectionType: '',
                formStatus: 0,
                formStatusLabel: ['', '未做/未检测', '不知道', '尚未获取', '不适用']
            };
        },
        created() {
            this.exampleId = this.$route.query.exampleId;
            // loadingInstance = Loading.service({fullscreen: true, text: 'Loading',})
            this.getTreeNode();
            this.headerFormName = this.$route.query.name
        },
        methods: {
            // 首次进入左侧下拉显示设置
            leftSelectsDefault() {
                try {
                    this.testeeValue = this.testeeList.filter(item => item.outpatientNumber == this.outpatientNumber)[0].pkId;
                } catch (error) {
                    console.log(error);
                }
            },
            //左侧3下拉改变
            testeeChange(val) {
                let visitData = this.testeeList.filter((item) => item.pkId === val)[0];
                this.testeeNumber = visitData.name;
                this.outpatientNumber = visitData.outpatientNumber;
                this.changePatientId = visitData.pkId;
                this.getSectionTrees();
                this.getTreeNode(this.handleItem);
            },
            //左侧3下拉数据获取
            getTestee() {
                let obj = {
                    researchCode: this.$route.query.researchCode,
                    centerKey: this.$route.query.centerKey
                };

                getTesteeList(obj)
                    .then(res => {
                        this.testeeList = res.data;
                        this.leftSelectsDefault();
                        // loadingInstance.close();
                    })
                    .catch(error => {});
            },
            getVisit(pkId, isLeftDefault) {
                let obj = {
                    researchCode: this.$route.query.researchCode,
                    centerKey: this.$route.query.centerKey,
                    pkId: pkId
                };
                getVisitList(obj)
                    .then(res => {
                        this.visitList = res.data;
                        if (isLeftDefault) {
                            this.visitItemData = this.visitList.filter((item) => item.exampleId === this.$route.query.exampleId)[0];
                            this.visitName = this.visitItemData.name;
                            this.visitValue = this.visitItemData.exampleId;
                            this.changePatientId = this.visitItemData.patientId;
                            // this.parentExampleId = this.visitItemData.exampleId;
                            this.getForm(this.visitValue, true);
                        }
                    })
                    .catch(error => {});
            },
            getForm(exampleId, isLeftDefault) {
                let obj = {
                    registryEntityCode: this.$route.query.researchCode,
                    code: this.visitItemData.code,
                    pkId: this.visitItemData.patientId,
                    exampleId: exampleId
                };
                this.parentExampleId = this.visitItemData.exampleId;
                getFormList(obj)
                    .then(res => {
                        this.sectionList = res.data.sectionList;
                        if (isLeftDefault) {
                            this.formValue = this.sectionList[0].code;
                            this.headerFormName = this.sectionList[0].name;
                            this.getTreeNode(this.isIndex);
                            // loadingInstance.close();
                        }
                    })
                    .catch(error => {});
            },
            // 标记
            markStatus(val) {
                let obj = {
                    registryEntityCode: this.researchCode,
                    sectionCode: this.handleItem.code,
                    patientId: this.changePatientId,
                    exampleId: this.handleItem.exampleId,
                    parentExampleId: this.parentExampleId,
                    status: val,
                    sectionType: this.handleItem.selectionType
                };
                console.log(obj, '标记表单 this.parentExampleId', this.parentExampleId);
                markSection(obj).then(res => {
                    this.$errInfo(val == 0 ? '取消标记成功' : res.data, 'success');
                    this.clearFormEntry(true);
                    this.mFormData = [];
                    this.handleNodeClick(this.handleItem, true);

                })

                if (val === 0) {
                    this.handleItem.status = 0;
                }

            },
            clearFormEntry(hided) {
                if (this.$refs.singleForm)
                    this.$refs.singleForm.clearFormValid(hided);
                if (this.$refs.multiForm)
                    this.$refs.multiForm.clearFormValid(hided);
            },
            //获取左侧树
            getSectionTrees() {
                let postData = {
                    registryEntityCode: this.$route.query.registryEntityCode,
                    patientId: this.changePatientId,
                    // centerKey: this.$route.query.centerKey,
                };
                getSectionTree(postData).then(res => {
                    console.log('左侧树', res.data);
                    this.treeData = res.data.data;
                    let firstVisit = this.treeData.filter(_ => _.code === this.parentCode)[0];
                    this.visitName = firstVisit.displayNameCn;
                    if (firstVisit.children && firstVisit.children.length > 0) {
                        this.headerFormName = firstVisit.children[0].displayNameCn;
                        this.defaultCheckTreeNode = [firstVisit.children[0].code];
                        this.handleNodeClick(firstVisit.children[0]);

                    }
                    // loadingInstance.close();
                }).catch(error => {
                    // loadingInstance.close();
                });
            },
            treeNodeTextHandle(data, node) {
                let text = data.displayNameCn;
                return text.length > 10
                    ? text.slice(0, 8) + "..."
                    : text;
            },
            //左侧树节点点击事件
            handleNodeClick(item, clearSingleForm) {
                this.handleItem = item;
                localStorage.removeItem('sectionDDMap')
                if (true != clearSingleForm)
                    this.handleItem.status = parseInt(
                        item.openMethod
                            ? (JSON.parse(item.openMethod)[this.changePatientId + '_' + item.parentExampleId]
                                ? JSON.parse(item.openMethod)[this.changePatientId + '_' + item.parentExampleId]
                                : 0
                            )
                            : 0
                    )

                console.log('左侧树点击', this.handleItem);
                this.exampleId = this.handleItem.exampleId;
                this.parentExampleId = this.handleItem.parentExampleId;

                if (item.parentCode !== 'Root') {
                    this.headerFormName = item.displayNameCn;
                    this.visitName = this.treeData.filter(_ => _.code === item.parentCode)[0].displayNameCn;
                    this.getTreeNode(item);
                    if (this.$refs['singleForm'])
                        this.$refs['singleForm'].handleFirstIn(item);
                    if (this.$refs['multiForm'])
                        this.$refs['multiForm'].handleFirstIn(item);

                } else
                    this.visitName = item.displayNameCn;

            },
            // 右侧结构
            getTreeNode() {
                this.isOnload = false;
                this.$refs['dynamicForm'] && this.$refs['dynamicForm'].resetFields();
                let obj = {
                    registryEntityCode: this.$route.query.registryEntityCode,
                    selectionType: this.$route.query.selectionType,
                    code: this.$route.query.sectionCode,
                    patientId: this.$route.query.patientId,
                    roleId:localStorage.getItem('user'),
                    parentCode: this.$route.query.parentCode,
                    viewLevel: 1
                };
                this.handleItem = {
                    registryEntityCode: this.$route.query.registryEntityCode,
                    selectionType: this.$route.query.selectionType,
                    code: this.$route.query.sectionCode,
                    patientId: this.$route.query.patientId,
                    roleId:localStorage.getItem('user'),
                    parentCode: this.$route.query.parentCode,
                }
                getTreeNodeData(obj).then(res => {
                    this.currentSectionType = this.$route.query.selectionType
                    this.isOnload = true;
                    console.log('获取右侧结构', res.data);
                    this.handleItem.status = res.data.formStatus;
                    if (this.currentSectionType === "Single") {
                        let dataMap = res.data.ddMap;
                        for (let k in dataMap) {
                            let group = dataMap[k];
                            for (let i in group.groupMap) {
                                let item = group.groupMap[i];
                                // handleFormData(item, "strToOther");
                                if (item.controlType === "SUF") {
                                    item.value = item.value ? eval("(" + item.value + ")") : [];
                                    item.fileList = item.value;
                                }
                                if (item.controlType == 'NTX' && item.ddInputGuide)
                                    item.numConfig = JSON.parse(item.ddInputGuide);

                                this.dynamicForm.singleSectionConditionMap[i] = {hided: item.visible == 0};
                            }
                            if (group.isRepeat == 1) {

                                this.dynamicForm.singleSectionConditionMap[group.groupCode] = {hided: group.visible == 0};
                            }    
                        }
                        if (localStorage.getItem('sectionDDMap')) {
                             this.dynamicForm.sectionDDMap = JSON.parse(localStorage.getItem('sectionDDMap'));
                        }else{
                           this.dynamicForm.sectionDDMap = JSON.parse(JSON.stringify(dataMap));
                        }
                        console.log(this.dynamicForm.sectionDDMap);
                    }
                    
                    else if (this.currentSectionType === "Multiple") {
                        this.cols = res.data.label;
                        this.dataMap = res.data.dataMap;
                        if (Object.keys(res.data.dataMap).length > 0) {
                            let formList = [];
                            Object.keys(res.data.dataMap).forEach(key => {
                                    let obj = {};
                                    let code = "";
                                    res.data.dataMap[key].forEach(item => {
                                        // console.log('---item', item);
                                        code = item.ddCode;
                                        if (item.controlType === "SEL_SIG") {
                                            let selObj = item.itemOptionList.filter(
                                                v => item.value === v.value
                                            );
                                            obj[code] = selObj && selObj.length > 0 ? selObj[0].displayName : '';
                                        } else if (item.controlType === "ADSEL") {
                                            let addressArr = '';
                                            item.value.split(',').forEach(item => {
                                                addressArr += CodeToText[item]
                                            });
                                            obj[code] = addressArr;
                                        } else if (item.controlType == 'NTX' && item.ddDisplayNameCn) {
                                            item.numConfig = JSON.parse(item.ddDisplayNameCn);
                                            obj[code] = item.value;
                                        } else {
                                            obj[code] = item.value;
                                        }
                                        obj["id"] = key;
                                        obj["pkId"] = item.pkId;
                                        obj["ddValueIds"] = item.ddValueIds || []
                                    });
                                    formList.push(obj);
                                }
                            );
                            this.mFormData = formList;
                        } else {
                            this.mFormData = [];
                        }
                    }
                    // loadingInstance.close();
                }).catch(error => {
                    // loadingInstance.close();
                });
            },
            // 多表
            tableHeaderCn() {
                return "table_header";
            }
            ,
            // 打开弹出层,重复表单获取表单信息
            formDialog(val, data) {
                console.log('data', data);
                if (val === "add") {
                    getStructureDdList(this.multiFormParam).then(res => {
                        this.dialogStatus = "create";
                        let dataMap = res.data.ddMap;
                        for (let k in dataMap) {
                            let item = dataMap[k];
                            handleFormData(item, "strToOther");
                            this.dynamicForm.singleSectionConditionMap[k] = {hided: item.visible == 0};
                        }
                        this.dynamicForm.sectionDDMap = dataMap;
                        this.formDialogVisible = true;
                    })
                } else if (val === "edit") {
                    let key = data.id;
                    this.editKey = key;
                    this.dialogStatus = "update";
                    this.exampleId = key;
                    this.ddValueIds = data.ddValueIds
                    let editCon = Object.assign({}, this.dataMap);
                    editCon[key].forEach(item => {
                        handleFormData(item, "strToOther");
                        this.dynamicForm.sectionDDMap[item.ddCode] = item
                        this.dynamicForm.singleSectionConditionMap[item.ddCode] = {hided: item.visible == 0}
                    });

                    this.formDialogVisible = true;
                }
                if (val === "check") {
                    let key = data.id;
                    let editCon = Object.assign({}, this.dataMap);
                    editCon[key].forEach(item => {
                        handleFormData(item, "strToOther");
                        this.dynamicForm.sectionDDMap[item.ddCode] = item
                        this.dynamicForm.singleSectionConditionMap[item.ddCode] = {hided: item.visible == 0}
                    });

                    this.dialogStatus = "check";
                    this.formDialogVisible = true;
                }
            }
            ,
            closeFrom(val) {
                if (val) {
                    // if (this.dialogStatus !== "check") {
                    this.getTreeNode(this.handleItem);
                    // }
                    this.dynamicForm.sectionDDMap = {};
                    this.dialogStatus = "";
                    this.formDialogVisible = false;
                }
            }
            
            ,
            updateDynamicForm(d) {
                this.dynamicForm = d;

            },
            // 删除多表
            removeForm(id) {
                deleteFormEntry(id).then(res => {
                    this.$message({
                        type: "success",
                        message: res.data
                    });
                    this.getTreeNode(this.handleItem);
                }).catch(error => {
                    console.log(error);
                });
            },
            backtop(){
                
                let timer = setInterval(function () {
                    let osTop = document.getElementsByClassName('col-wrap-s')[0].scrollTop
                    console.log("🚀 ~ file: FormEntry.vue ~ line 1012 ~ timer ~ osTop", osTop)
                    // let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                    let ispeed = Math.floor(-osTop / 5);
                    document.getElementsByClassName('col-wrap-s')[0].scrollTop = osTop + ispeed;
                    osTop === 0 && clearInterval(timer)
                }, 30)
            }
        },
        beforeRouteLeave(to,from,next){
            localStorage.removeItem('sectionDDMap')
             loadingInstance && loadingInstance.close();
            next()
        }
    }
    ;
</script>

<style scoped lang="scss">
/deep/.el-row{
    padding-right: 10px;
    padding-top: 10px;
}
/deep/.el-card__body{
    padding:10px !important
}
/deep/.el-tree-node__expand-icon.expanded{
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
}
/deep/.tree-line{
    .is-leaf:before{
        display: block;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #2c5c89;
        color:#fff;
        line-height: 11px;
        font-size: 20px;
        text-align: center;
    }
    .el-tree-node {
        position: relative;
        padding-left: 15px;
    }
    .el-tree-node__children {
        padding-left: 15px;
    }
    .el-tree-node::before {
        content: "";
        height: 100%;
        width: 1px;
        position: absolute;
        left: -3px;
        top: -26px;
        border-width: 1px;
        border-left: 1px dashed #52627C;
    }
     // 当前层最后一个节点的竖线高度固定
    .el-tree-node:last-child:before {
        height: 40px;
    }
    .el-tree-node::after {
        content: "";
        width: 24px;
        height: 20px;
        position: absolute;
        left: -3px;
        top: 13px;
        border-width: 1px;
        border-top: 1px dashed #52627C;
    }
    & > .el-tree-node:after {
        border-top: none;
    }
    & > .el-tree-node:before {
        border-left: none;
  }

}
 /deep/.el-tree-node__expand-icon:before{
    display: block;
    content:'+';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #2c5c89;
    color:#fff;
    line-height: 11px;
    font-size: 20px;
    text-align: center;
}
/deep/.expanded:before{
    content:'-';
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #2c5c89;
    color:#fff;
    line-height: 11px;
    font-size: 20px;
    text-align: center;
}

    #formEntry {
        position: absolute;
        left: 10px;
        right: 10px;
        bottom: 20px;
        top: 100px;
        .form-title {
            text-align: center;
            font-size: 18px;
            background: #e9ecef;
            color: #212121;
            margin-bottom: 10px;
            min-height: 40px;
            line-height: 40px;
        }
        .formNav {
            padding: 0 16px;
            .formNav-label {
                display: block;
                font-size: 14px;
                color: #212121;
                line-height: 16px;
                margin: 10px 0;
            }
            .menu-list .nav-item {
                a {
                    display: block;
                    height: 40px;
                    color: #444;
                    line-height: 40px;
                    font-size: 14px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-weight: 400;
                    cursor: pointer;
                }
                a.active,
                a:hover {
                    color: #409eff;
                }
            }
        }
        .formStatus {
            // background: #f7f7f7;
            padding: 0px 15px 0;
            width: 300px;
            height: 50px;
            line-height: 50px;
        }
        .formCon {
            // background: #f7f7f7;
            margin-top: 5px;
            .el-form-item {
                background: #ffffff;
                // padding: 0 15px 15px;
            }
            .el-input {
                width: 270px;
            }
            .el-textarea {
                width: 270px;
            }
            .formLabel {
                font-weight: bold;
                margin-bottom: 15px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
            }
        }
    }
    .el-form-item{
        margin-bottom: 0;
    }
</style>
<style lang="scss">
    .table_header {
        background-color: #e9ecef !important;
        border-right: none !important;
        color: #000 !important;
    }
    .form-wrap {
        display: flex;
        justify-content: center;
        padding-top: 30px;
    }
</style>